import { isEmpty } from 'lodash';
import { customAlphabet } from 'nanoid';

const genShortId = customAlphabet('123456789abcdefghijklmnopqrstuvwxyz_', 7);
const genLongId = customAlphabet('123456789abcdefghijklmnopqrstuvwxyz_', 12);
const genShortConfirmationCode = customAlphabet('123456789ABDDEFGHIJKLMNOPQRSTUVWXYZ', 6);

export function getTrySurveyLink({ shortId, custom1 }: { shortId?: string, custom1?: string }) {
  const queryArgument = isEmpty(custom1) ? "" : `?custom1=${btoa(custom1!)}`;
  return `/s/1/${shortId}${queryArgument}`;
}

export function getSurveyLink({
  orgIdParam,
  surveyId,
}: {
  orgIdParam?: string | null;
  surveyId?: string | null;
}) {
  if (!orgIdParam) {
    // throw new Error('param not found');
    // eslint-disable-next-line
    console.log('surveylink: orgid param not found');
    // debugger;
  }
  return `/admin/${orgIdParam}/survey/${surveyId}`;
}

export function getReportsLink({ orgIdParam }: { orgIdParam?: string | null }) {
  if (!orgIdParam) {
    // eslint-disable-next-line
    console.log('reportlink: orgid param not found');
  }
  return `/admin/${orgIdParam}/reports`;
}

export function getReportLink(params: { orgIdParam?: string | null; surveyId?: string | null }) {
  const { surveyId } = params;
  return `${getReportsLink(params)}/${surveyId}`;
}

export function openPatientSurvey({ shortId, custom1 }: { shortId?: string, custom1?: string }) {
  window.open(
    getTrySurveyLink({
      shortId,
      custom1
    }),
    '_blank'
  );
}

export function getSurveyStepLink(params: {
  orgIdParam?: string | null;
  surveyId?: string | null;
}) {
  const { orgIdParam, surveyId } = params;

  if (!orgIdParam || !surveyId) {
    return '/admin';
  }

  return `${getSurveyLink(params)}/steps`;
}

export function getSurveyStepTreeLink(params: {
  orgIdParam?: string | null;
  surveyId?: string | null;
  treeId: string;
}) {
  const { orgIdParam, surveyId, treeId } = params;

  if (!orgIdParam || !surveyId) {
    return '/admin';
  }

  return `${getSurveyLink(params)}/steps/${treeId}`;
}

export function getSurveyTransLink(params: {
  orgIdParam?: string | null;
  surveyId?: string | null;
}) {
  return `${getSurveyLink(params)}/trans`;
}

export function getSurveyQsLink(params: { orgIdParam?: string | null; surveyId?: string | null }) {
  return `${getSurveyLink(params)}/questions`;
}

export function getSurveySxLink(params: { orgIdParam?: string | null; surveyId?: string | null }) {
  return `${getSurveyLink(params)}/sx`;
}

export function getSurveyTemplateLink(params: {
  orgIdParam?: string | null;
  surveyId?: string | null;
}) {
  return `${getSurveyLink(params)}/template`;
}

export function getSurveySettingsLink(params: {
  orgIdParam?: string | null;
  surveyId?: string | null;
}) {
  return `${getSurveyLink(params)}/settings`;
}

export function genSurveyShortId() {
  return genShortId();
}

export function genMessageLongId() {
  return genLongId();
}

export function genVisitId() {
  return genShortConfirmationCode();
}

