import { Enum_Translation_Locale } from '@cyren/common-lib';
import classNames from 'classnames';
import { first } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useStaticLocales } from '../../admin/hooks/locales/use-static-locale';
import { useToastMsgs } from '../../admin/hooks/use-toast-msgs';
import { useData } from '../../report/use-data';
import { genStepDescKey, genStepNameKey } from '../../utils/models/trees';
import { PaReportState, useQsVisibilitySync } from '../hooks/use-report';
import { useTranslations } from '../hooks/use-translations';
import { useTreeHelpers } from '../hooks/use-tree-helpers';
import { ModeT, TOptions, UpdateAnswerHandler, UpdateAnswerValueHandler } from '../pa-prop-types';
import { Key, Keys, TreeState, UIMetadataT } from '../patient-types';
import PaLocalStorageReset from './PaLocalStorageReset';
import PaTreeHeader from './PaTreeHeader';
import Question from './Question';
import useConfirmationDialog from '../../hooks/use-confirmation-dialog';
import ContentError from '../../common/content-error/content-error';
import { useRecoilState } from 'recoil';

function PaQuestionTree({
  level,
  treeKey,
  onSave,
  hasNext,
  onNext,
  tOptions,
  updateAnswer,
  updateValue,
  onUiMetadataChange,
  treeState,
  locale,
  noHeader,
  mode
}: {
  level: number;
  treeKey: string;
  hasNext?: boolean;
  onNext?: () => void;
  onSave?: () => void;
  tOptions?: TOptions;
  treeState?: TreeState;
  updateAnswer: UpdateAnswerHandler;
  updateValue: UpdateAnswerValueHandler;
  locale: Enum_Translation_Locale;
  onUiMetadataChange?: (p: { uiMetadata: UIMetadataT; questionKey: Key }) => void;
  noHeader?: boolean;
  mode?: ModeT;
}) {
  const [
    {
      dataState: { survey },
    },
    { getTreeByKey, getQuestionsByKeys },
  ] = useData();

  const [reportState] = useRecoilState(PaReportState);

  // make all system values usable as macro replacements
  const extendedTOptions = {
    ...tOptions,
    ...(reportState?.systemValueMap ? Object.fromEntries(
      Object.entries(reportState?.systemValueMap).filter(([, value]) => typeof value === 'string'))
      : {})
  } as TOptions;

  const [, { t }] = useTranslations({ locale });
  const { sltStr } = useStaticLocales();
  const { showAnswerAll } = useToastMsgs();
  const [confirmDialog, confirmationDialogUX] = useConfirmationDialog();

  const [{ isValid, qsKeysNotAnswered, qsErrorMap }] = useQsVisibilitySync({
    treeState,
  });

  const [dirty, setDirty] = useState(false);
  useEffect(() => {
    if (treeKey) {
      setDirty(false);
    }
  }, [treeKey]);

  const [errorQsKeys, setErrorQsKeys] = useState<Keys>([]);
  const tree = treeKey ? getTreeByKey(treeKey) : null;
  const questions = tree ? getQuestionsByKeys(tree.entryQuestionKeys) : null;
  const [, { isQuestionVisible }] = useTreeHelpers({ treeState });

  // eslint-disable-next-line
  /* console.log('treeKey', treeKey); */
  /* console.log('tree', tree); */
  /* console.log('questions', questions); */
  // console.log('treeState', treeState);

  if (!treeState) {
    // TODO handle null
    return null;
  }

  // console.log('tree', tree);
  // console.log('treeIdx', treeIdx);
  // console.log('survey', survey);

  const nameKey = genStepNameKey(tree);
  const descKey = genStepDescKey(tree);

  const treeLabel = t(nameKey || '', {}, { fallbackValue: '' });
  const desc = t(descKey || '', {}, { fallbackValue: '' });
  const showHeader = !tree?.stageBoundary;

  const handleNext = async () => {
    if (!onNext) {
      return;
    }
    setDirty(true);

    if (!isValid) {
      const firstKeyNotAnswered = first(qsKeysNotAnswered);
      setErrorQsKeys(qsKeysNotAnswered);

      if (firstKeyNotAnswered) {
        const el = document.getElementById(firstKeyNotAnswered);
        el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        showAnswerAll();
      }
      return;
    }

    try {
      onNext();
    }
    catch(error) {
      if (error instanceof ContentError) {
        const yes = await confirmDialog("", 
          sltStr({ key: 'msg-confirm-save' }),
          sltStr({ key: 'btn-cancel' }), 
          sltStr({ key: 'btn-submit' }));
        if (!yes) {
          return;
        }    
        if (onSave) onSave();
      }
      else {
        throw error;
      }
    }
  }

  return (
    <ErrorBoundary FallbackComponent={PaLocalStorageReset}>
      <div className='t_PaQuestionTree flex-1 flex-col gap-2'>
        {noHeader !== true && <PaTreeHeader survey={survey} tree={tree} />}
        <div className='px-5 flex-col gap-8'>
          {(treeLabel || desc) && showHeader && (
            <div
              className='flex-col gap-3'
              onClick={() => {
                // eslint-disable-next-line
                console.log('treeState', treeState);
              }}
            >
              {treeLabel && (
                <span className='flex-row justify-center'>
                  <div className={classNames('text-lg font-bold text-primary')}>{treeLabel}</div>
                </span>
              )}
              {desc && (
                <div className='flex-1 inline-block text-center'>
                  <div className='text-desc'>{desc}</div>
                </div>
              )}
            </div>
          )}
          {questions?.map((question) => {
            if (!question) return null;

            const { questionKey } = question;

            const isVisible = isQuestionVisible({ question, treeKey });

            // eslint-disable-next-line
            // console.log(question.questionKey, 'isVisible', isVisible);

            if (!isVisible) {
              return null;
            }

            return (
              <Question
                level={level}
                dirty={dirty}
                error={errorQsKeys.includes(questionKey)}
                errorMsgs={qsErrorMap?.[question?.questionKey || '']}
                key={question?.questionKey}
                treeKey={treeKey}
                question={question}
                treeState={treeState}
                updateAnswer={updateAnswer}
                updateValue={updateValue}
                tOptions={extendedTOptions}
                onUiMetadataChange={onUiMetadataChange}
                locale={locale}
                mode={mode}
                onNext={handleNext}
              />
            );
          })}

          <div className='flex'>
            {confirmationDialogUX}
            {!hasNext && onSave && (
              <button
                className={classNames('btn btn-primary flex-1 btn-lg')}
                onClick={async () => {
                  setDirty(true);

                  if (!isValid) {
                    const firstKeyNotAnswered = first(qsKeysNotAnswered);
                    setErrorQsKeys(qsKeysNotAnswered);

                    if (firstKeyNotAnswered) {
                      const el = document.getElementById(firstKeyNotAnswered);
                      el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                      showAnswerAll();
                    }
                    return;
                  }

                  const yes = await confirmDialog("", 
                    sltStr({ key: 'msg-confirm-save' }),
                    sltStr({ key: 'btn-cancel' }), 
                    sltStr({ key: 'btn-submit' }));
                  if (!yes) {
                    return;
                  }

                  onSave();
                }}
              >
                {sltStr({ key: 'btn-save', capit: true })}
              </button>
            )}

            {hasNext && onNext && (
              <button
                className={classNames('btn btn-primary flex-1 btn-lg')}
                onClick={handleNext}
              >
                {sltStr({ key: 'btn-next', capit: true })}
              </button>
            )}
          </div>
          <div className='h-10' />
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default memo(PaQuestionTree);
