import { Enum_Questiontree_Treetype, formatDate, isNotEmpty, notNull, removeHtmlTags, SystemDate } from '@cyren/common-lib';
import { first, isEmpty, size, uniq, values } from 'lodash';
import { AdhocQuestionType, AnswerValue, Key, Keys, TreeState } from '../../patient/patient-types';
import {
  FlowLineConfigT,
  ItemTypeT,
  LineConfigT,
  PHITranslated,
  ReportConfigStateT,
  TreeTemplateMap,
} from '../../survey/template/template-types';
import { TTransApi } from '../../graphql-enhanced';

export const tab = '   ';

export function getQsKeysFromTemplate({
  template,
  itemTypes,
}: {
  template: ReportConfigStateT;
  itemTypes: ItemTypeT[];
}) {
  const qsKeys =
    template?.lines?.reduce((prev, line) => {
      const qsKeysInItem =
        line?.items?.reduce((prev2, item) => {
          if (item.questionKey && item.type && itemTypes.includes(item.type)) {
            return uniq([...prev2, item.questionKey]);
          }

          return prev2;
        }, [] as Keys) || [];

      return uniq([...prev, ...qsKeysInItem]);
    }, [] as Keys) || [];

  return qsKeys;
}

export function getConfigMap({
  sxKeys,
  treeTemplatesMap,
}: {
  sxKeys?: Keys | null;
  treeTemplatesMap: TreeTemplateMap;
}) {
  return sxKeys?.reduce((prev, key) => {
    return {
      ...prev,
      [key]: treeTemplatesMap[key] || {
        lines: [],
        treeKey: key,
      },
    };
  }, {} as TreeTemplateMap);
}

/*** NOTE: This function has been ported to the backend as well to support additional
 * Workflows.  If making modifications, make sure backend workflow is updated as well
 */

export function getPhis({
  treeStates,
  treeTemplatesMap,
  visitDate
}: {
  treeStates: TreeState[];
  treeTemplatesMap: TreeTemplateMap;
  visitDate: Date | null;
}) {
  const sxTreeStates = treeStates.filter(
    (ts) => ts.tree?.treeType === Enum_Questiontree_Treetype.Symptom
  );
  const treeKeys = sxTreeStates.map((ts) => ts.treeKey).filter(notNull);
  const phisConfigsMap = getConfigMap({ sxKeys: treeKeys, treeTemplatesMap });

  const phis = treeKeys
    .reduce(
      (
        prev: {
          treeKey: Key;
          onsetDate?: string;
          line: LineConfigT;
          relativeOnset: string | null;
        }[],
        phiTreeKey
      ) => {
        const sxTreeState = sxTreeStates.find((ts) => ts.treeKey === phiTreeKey);
        const onsets = phisConfigsMap?.[phiTreeKey].lines
          ?.map((sxLine, index) => {
            const { onsetQsKey } = sxLine;
            let onsetDate = onsetQsKey
              ? first(sxTreeState?.answerMap[onsetQsKey]?.answerValues)
              : '';
            let relativeOnset = null; 
            if (onsetQsKey && !isEmpty(sxTreeState?.answerMap[onsetQsKey]?.uiMetadata?.unit)) {
              const uiMetadata = sxTreeState?.answerMap[onsetQsKey]?.uiMetadata;
              relativeOnset = `${uiMetadata!.duration} ${uiMetadata!.unit}s ago`;
            }

            // onset date was not provided for the first onset key.  Use the visit date as a placeholder so that the data doesn't vanish
            if (isEmpty(onsetDate) && index === 0) {
              onsetDate = formatDate(visitDate || new Date(), { formatStr: SystemDate})
            }
            if (sxTreeState == null) return null;

            return {
              treeKey: phiTreeKey,
              line: sxLine,
              onsetDate,
              relativeOnset
            };
          })
          .filter(notNull)
          .filter((onset) => {
            // console.log('onset', onset);

            // if (onset?.onsetDate == null) {
            //   console.log('onset date is missing', onset.treeKey);
            // }
            return notNull(onset?.onsetDate);
          });

        if (onsets && !isEmpty(onsets)) {
          return [...prev, ...onsets];
        }

        // eslint-disable-next-line
        // console.log('onsets is empty', phiTreeKey, onsets);

        return prev;
      },
      []
    )
    .sort((phi1, phi2) => {
      if (phi1?.onsetDate == null || phi2?.onsetDate == null) return -1;

      const compareResult = phi1?.onsetDate > phi2?.onsetDate ? 1 : -1;
      return compareResult;
    });

  return phis;
}

export function getStringFromLine(line: FlowLineConfigT) {
  const outputArr: string[] = [];

  if (line.label) {
    outputArr.push(line.label);
  }

  line.items.forEach((item) => {
    item.reportConfig?.lines.forEach((repLine) => {
      if (repLine.output) {
        outputArr.push(repLine.output);
      }

      repLine.sublines.forEach((subline) => {
        if (subline.output) {
          outputArr.push(tab + subline.output);
        }

        subline.items.forEach((subitem) => {
          if (subitem.output) {
            outputArr.push(tab + subitem.output);
          }
        });
      });
    });
  });

  const outputStr = outputArr.join('\n');
  return removeHtmlTags(outputStr);
}

export function getStringFromPhis({
  line,
  phis,
}: {
  line: FlowLineConfigT;
  phis: PHITranslated[];
}) {
  const outputArr: string[] = [];

  if (line.label) {
    outputArr.push(line.label);
  }

  phis.forEach((phi) => {
    if (phi.onsetDate) {
      outputArr.push(`${phi.onsetDate} - ${phi.relativeOnset}`);
    }

    if (isNotEmpty(phi.lineOutput)) {
      outputArr.push(tab + phi.lineOutput);
    }

    if (isNotEmpty(phi.sublinesOutput)) {
      outputArr.push(tab + phi.sublinesOutput.join(`\n${tab}`));
    }
  });

  const outputStr = outputArr.join('\n');
  return removeHtmlTags(outputStr);
}


export function getStringFromAdHocQuestions(adHocQuestions: AdhocQuestionType[], userNoteTrans: TTransApi[], locale?: string | null) {
  const outputArr: string[] = ['Patient Communications'];

  adHocQuestions.forEach((item) => {
    let outputStr = "";
    outputStr = `${item.questionText}: `;
    if (item.answer) {
      const translatedAnswer = userNoteTrans.find((t) => 
        t.target === item.answer && t.target_locale === locale
    
    )?.output || item.answer;
      outputStr += translatedAnswer;
      if (translatedAnswer !== item.answer) {
        outputStr += ` [Auto-translated from ${item.answer}]`;
      }
    }
    else {
      outputStr += "Pending patient answer";
    }

    outputArr.push(outputStr);
  });

  const outputStr = outputArr.join('\n');
  return outputStr;
}


export function getReportAsStr({
  lines,
  phis,
  adHocQuestions,
  userNoteTrans,
  locale
  
}: {
  phis: PHITranslated[];
  lines: FlowLineConfigT[];
  adHocQuestions: AdhocQuestionType[];
  userNoteTrans: TTransApi[];
  locale?: string | null
}) {
  const outputArr: string[] = [];

  lines.forEach((line) => {
    const isPhiLine = phis && line.items[0].type === 'rep-phi-list';

    let outputStr;
    if (isPhiLine) {
      outputStr = getStringFromPhis({ line, phis });
    } else {
      outputStr = getStringFromLine(line);
    }

    if (outputStr) {
      outputArr.push(outputStr);
    }
  });

  if (size(adHocQuestions)) {
    outputArr.push(getStringFromAdHocQuestions(adHocQuestions, userNoteTrans, locale));
  }

  const output = outputArr.join('\n\n');

  return output;
}

export const getKeysFromAnswerValue = (answerValue: AnswerValue) => {
  let list = [...(answerValue?.answerKeys || [])];
  answerValue?.answerMaps?.forEach((answerMap) => {
    values(answerMap).forEach((answerValueL1) => {
      list = [...list, ...(answerValueL1?.answerKeys || [])];
    });
  });

  return list;
};
