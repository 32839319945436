import { isEmpty, trim } from 'lodash';
import slugify from 'slugify';
export function containLowerCase(str) {
    return !!str?.match(/[a-z]/);
}
export function containsNoUppercase(str) {
    return !str?.match(/[A-Z]/);
}
export function strOrNull(str) {
    return !isTrimEmpty(str) ? str : null;
}
export function isTrimEmpty(str) {
    return isEmpty(trim(str));
}
export function slugifyKey(key) {
    return slugify(key || '', {
        lower: true,
        replacement: '-',
        strict: true,
        trim: false,
    });
}
export function hasOnlyValidEnglishNameCharacters(input) {
    // note: allowing digits in names to facilitate testing situations 
    // where multiple versions of names might need to be differentiated
    // with a number
    return /^[\sA-Za-z0-9'-.,:]+$/u.test(input);
}
const regexOnlyNumbersPunctuationWhitespace = /^[\d\s\p{P}]*$/u;
export function hasOnlyNumbersAndPunctuationCharacters(input) {
    return regexOnlyNumbersPunctuationWhitespace.test(input);
}
export function truncateFromMiddle(text, startChars, endChars, maxLength) {
    if (!text || isEmpty(text)) {
        return text;
    }
    if (text.length > maxLength) {
        var start = text.substring(0, startChars);
        var end = text.substring(text.length - endChars, text.length);
        return start + '...' + end;
    }
    return text;
}
export function removeHtmlTags(input) {
    // Use a regular expression to match and remove HTML tags
    return input.replace(/<\/?[^>]+(>|$)/g, "");
}
