import { Suspense, lazy } from 'react';
import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import PageLoading from '../common/loading/PageLoading';
import DemoEmr2 from '../patient-demo/DemoEmr2';
import PaBasicInfo from '../patient-demo/PaBasicInfo';
import QrRedirect from '../qr/QrRedirect';
import App404Refresh from './App404Refresh';
import AppRoutesPatient from './AppRoutesPatient';
import AuthLayoutStaff from './AuthLayoutStaff';
import PatientLayout from './PatientLayout';
import RootLayout from './RootLayout';
import PaPatientMessageRedirect from '../patient/adhoc/PaPatientMessageRedirect';
import PaProviderReport from '../patient/survey/PaProviderReport';
import PrintableUniqueQRCode from '../survey/PrintableUniqueQRCode';

const Four04 = lazy(() => import('../404/Four04'));
const AppRoutesAdmin = lazy(() => import('./AppRoutesAdmin'));
const AppRoutesAdminStaff = lazy(() => import('./AppRoutesAdminStaff'));
const OnboardingPage = lazy(() => import('../admin/members/OnboardingPage'));
const AdminAuthLayout = lazy(() => import('../admin/auth/AdminAuthLayout'));
const AuthLayoutV2 = lazy(() => import('./AuthLayoutV2'));
const PatientDemo = lazy(() => import('../patient-demo/PatientDemo'));
const DemoEmr = lazy(() => import('../patient-demo/DemoEmr'));

export default createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayout />}>
      <Route path='demo-emr' element={<Navigate to='/demos/emr' />} />

      <Route path='/demos'>
        <Route path='emr' element={<DemoEmr />} />
        <Route path='multiple-inputs-widget' element={<PaBasicInfo />} />
        <Route path='multiple-inputs' element={<DemoEmr2 />} />
      </Route>

      <Route path='/test/refresh' element={<App404Refresh />} />

      {/* Redirect URL */}
      <Route path='/u/:uniqId' element={<QrRedirect />} />

      <Route
        path='/demo'
        element={
          <Suspense fallback={<PageLoading />}>
            <PatientDemo />
          </Suspense>
        }
      />

      {/* DEPRECATED */}
      <Route path='/browse/*' element={<Navigate to='/admin' />} />
      <Route
        path='/tools/browse'
        element={
          <Suspense fallback={<PageLoading />}>
            <PatientDemo />
          </Suspense>
        }
      />


      <Route
        path='/tools/uniqueQRCode'
        element={
          <Suspense fallback={<PageLoading />}>
            <PrintableUniqueQRCode />
          </Suspense>
        }
      />


      <Route path='/tools/*' element={<Navigate to='/admin' />} />

      {/* ADMIN STAFF LOGIN WITH WIDGET */}
      <Route
        path='/admin/staff'
        element={
          <Suspense fallback={<PageLoading className='h-screen' />}>
            <AuthLayoutStaff />
          </Suspense>
        }
      ></Route>
      <Route
        path='/admin/staff/:orgIdParam'
        element={
          <Suspense fallback={<PageLoading className='h-screen' />}>
            <AuthLayoutStaff />
          </Suspense>
        }
      >
        <Route path='*' element={<AppRoutesAdminStaff />} />
      </Route>

      <Route
        path='/admin/auth/*'
        element={
          <Suspense fallback={<PageLoading />}>
            <AdminAuthLayout />
          </Suspense>
        }
      />

      {/* ONBOARDING USER TO ADMIN TOOL */}
      <Route
        path='/admin/onboarding'
        element={
          <Suspense fallback={<PageLoading />}>
            <OnboardingPage />
          </Suspense>
        }
      />

      <Route
        path='/admin'
        element={
          <Suspense fallback={<PageLoading className='h-screen' />}>
            <AuthLayoutV2 />
          </Suspense>
        }
      />
      <Route
        path='/admin/:orgIdParam'
        element={
          <Suspense fallback={<PageLoading className='h-screen' />}>
            <AuthLayoutV2 />
          </Suspense>
        }
      >
        <Route index element={<Navigate to='reports' />} />
        <Route path='*' element={<AppRoutesAdmin />} />
      </Route>

      {/* PATIENT FLOW */}
      <Route path='/s' element={<PatientLayout />}>
        <Route path='*' element={<AppRoutesPatient />} />
      </Route>
      <Route path='/sp/:uuid' element={<PaProviderReport />} />

      <Route path='/m/:referralMessageId' element={<PatientLayout><PaPatientMessageRedirect /></PatientLayout>} />


      <Route
        path='*'
        element={
          <Suspense fallback={<PageLoading />}>
            <Four04 />
          </Suspense>
        }
      />
    </Route>
  )
);
